<template>
<div>
    <v-form ref="changeEmail" v-model="validForm">
        <v-text-field v-model="form.email" :label="$store.getters.LabelController.getLabel('authentication.form.label.change_email')" disabled/>
        <v-text-field v-model="newEmail" :label="$store.getters.LabelController.getLabel('authentication.form.label.new_email')" required :rules="rules.email"/>

        <v-otp-input length="6" v-model="form.pin" type="number" required :rules="rules.otp"/>
    </v-form>
    <Str index="authentication.form.label.reset_pin"/>
    <v-card-actions>
        <a @click="back"><Str index="authentication.form.button.back"/></a>
        <v-spacer></v-spacer>
        <v-btn @click="requestPIN" :disabled="!validEmail" color="blue" dark x-large tile><Str index="authentication.form.button.request_reset_pin"/></v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="!validForm" @click="changeEmail" color="blue" dark x-large tile><Str index="authentication.form.button.change"/></v-btn>
    </v-card-actions>
    
    <v-snackbar v-model="ui.snackbar.display">
        {{ ui.snackbar.message }}

        <template v-slot:action="{ attrs }">
        <v-btn class="pink-brand" text v-bind="attrs" @click="ui.snackbar.display = false">
            <Str index="authentication.form.button.close"/>
        </v-btn>
        </template>
    </v-snackbar>      
</div>
</template>
  
  <script>
  export default {
    name: "ChangeEmail",
      created(){
          this.form.email = this.model
          this.formRules = new this.formRulesAPI(this)
      },
      data(){
          return {
              validForm: false,
              formRules: undefined,
              ui: {
                  snackbar: {
                      display: false,
                      message: null
                  }
              },
              form: {
                  email: null,
                  pin: null
              },
              newEmail: null,
              rules: {
                  otp: [
                      v => this.formRules.rules.answered(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.required_field'),
                      v => this.formRules.rules.length(v,6) || this.$store.getters.LabelController.getLabel('authentication.resetPin.invalid')
                  ],
                  email: [
                      v => this.formRules.rules.validEmail(v) || this.$store.getters.LabelController.getLabel('authentication.form.label.invalid_entry')
                  ]
              }
          }
      },
      methods: {
          back(){
              this.$emit('back')
          },
          async requestPIN(){
              this.form.pin = null
              let response = await this.controller.requestEmailChange(this.newEmail)
              console.log('requestPIN',response)
              this.ui.snackbar.message = this.$store.getters.LabelController.getLabel('authentication.resetPin.sent')
              this.ui.snackbar.display = true
              
          },
          async changeEmail(){
              let email = this.form.email
              let code = this.form.pin
              let response = await this.controller.verifyEmailChange(code)
              if(response){
                let hsController = this.$store.getters.UserController.hs
                let newEmail = this.newEmail
                let properties = hsController.contact.transformCognitoFields({email: newEmail})
                await hsController.contact.update({email, properties})

                  this.ui.snackbar.message = this.$store.getters.LabelController.getLabel('authentication.changePassword.success')
                  this.ui.snackbar.display = true
                  let self = this
                  setTimeout(() => {
                    self.$emit('success',response)
                    self.controller.signOut()
                  }, 2000);
              }else{
                  this.ui.snackbar.message = this.$store.getters.LabelController.getLabel('authentication.changePassword.fail')
                  this.ui.snackbar.display = true
              }
          }
      },
      computed:{
          model(){
              return this.$attrs.value
          },
          controller(){
              return this.$store.getters.UserController
          },
          email(){
              return this.newEmail
          },
          validEmail(){
              let formRules = this.formRules
              return formRules ? formRules.rules.validEmail(this.newEmail) : false
          }
      },
      watch: {
          model(){
              this.form.email = this.model
          },
          email(){
              if(this.validEmail){
                  this.$emit('input',this.form.email)
              }
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .theme--dark.v-btn.v-btn--disabled{
    background-color: $lightgray !important;
    color: $gray !important;
  }
  
  
::v-deep .pink-brand {
    color: $pink !important;
}
  </style>
<template>
  <div class="home">
    <Banner/>
    <Testimonials/>
    <Outline/>
    <Expand/>
    <Experts/>
    <Acknowledgement/>
    <ObesityCanada/>
    <Misc/>
    
    <div class="d-flex flex-column align-center pa-2" v-show="showFabToTop" style="position:fixed; right:0; bottom:0; z-index:100;">
        <v-btn
          v-scroll="onScroll"
          v-show="showFabToTop"
          fab
          dark
          small
          color="transparent"
          @click="toTop"
          style="z-index:100;"
        >
          <img width="40" :src="icons.back_to_top"/>
        </v-btn>
        <p v-show="showFabToTop" class="pt-1 pb-13"><Str index="home.button.back_to_top"/></p>
    </div>    
    
  </div>
</template>

<script>
import Banner from '@/components/Home/Banner'
import Testimonials from '@/components/Home/Testimonials'
import Outline from '@/components/Home/Outline'
import Expand from '@/components/Home/Expand'
import Experts from '@/components/Home/Experts'
import Acknowledgement from '@/components/Home/Acknowledgement'
import ObesityCanada from '@/components/Home/ObesityCanada'
import Misc from '@/components/Home/Misc'
import BackToTopIcon from '@/assets/icons/ic-back-to-top.svg'
export default {
  name: 'Home',
  components: {
    Banner,
    Testimonials,
    Outline,
    Expand,
    Experts,
    Acknowledgement,
    ObesityCanada,
    Misc
  },
  data(){
    return {
        showFabToTop: false
    }
  },
  computed: {
    icons(){
      return {
        back_to_top: BackToTopIcon,
      }
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.showFabToTop = top > 360
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style lang="scss">
.home a{
  text-decoration: none;
  border: 1px solid $pink;
  padding: 15px 25px;
  color: white;
  background-color: $pink;
  cursor: pointer;
}
.home a:hover{
  border-color: lightgrey;
}
</style>
<template>
<div :class="['home', 
     {'cta-jumbotron':options.flavor==='cta-jumbotron'},
     {'cta-plain':options.flavor==='cta-plain'},
     {'cta-outline':options.flavor==='cta-outline'},
     {'cta-expand':options.flavor==='cta-expand'}]">
    <router-link v-if="!$store.getters.UserController.authenticated" :to="{name:'Sign In'}">
        <Str index="header.sign_in"/>
    </router-link>
    <router-link v-else :to="{name:'Educational Videos'}">
        <Str :index="$store.getters.VideoController.watched.length==0 ? 'header.start' : 'header.continue_program'"/>
    </router-link>
</div>        
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
            default: () => ({ // <= note the parenthesis
                flavor:'regular'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.cta-jumbotron {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0;
    margin-right: 0;
    background-color: transparent !important;
    width: 100%;
    color: transparent;
    
    a {
        display: block;
        color: white;
    }
}

.cta-outline {
    a{
        background-color: transparent !important;
        border: 4px solid $gray !important;
        color: $gray !important;
        padding-left: 25px;
        padding-right: 25px;
    }
    a:hover{
        border-color: $midgray !important;
    }   
}

.cta-expand {
    a{
        padding-left: 8px;
        padding-right: 8px;
        width: 100%;
    }
}

a {
    width: 100%;
    font-size: 24px !important;
    border: 3px solid white !important; 
    text-align: center;
    color: white !important;    
}

@media only screen and (max-width: 600px) {
.cta-expand { 
    a{
        display: flex;
    }
}

.cta-plain {
    text-align: center;
}
}
</style>
<template>
<v-container class="outline" fluid>
    <v-row justify="center" :class="[{'flex-column':$vuetify.breakpoint.width<=616}]">
        <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"/>
        <v-col align-self="center" :class="[{'pb-7 text-center':$vuetify.breakpoint.width<=616}]">
            <h1><Str index="home.outline.title"/></h1>
            <p><Str index="home.outline.body"/></p>
            <StartButton class="pt-11" :options="{flavor:'cta-outline'}"/>
        </v-col>
        <v-col class="steps">
            <v-container fluid>
                <v-row :class="[{'flex-column':$vuetify.breakpoint.width<=768}, {'text-center':$vuetify.breakpoint.width<=616}]">
                    <v-col style="text-align: center;">
                        <div :class="['hex-box', 'custom-pink', 'float-lg-right', {'mx-auto':$vuetify.breakpoint.width<=616}]"  :style="{'background-image':`url('${image.Step1}')`}"/>
                    </v-col>
                    <v-col>
                        <h2><Str index="home.outline.step.1.title"/></h2>
                        <Str index="home.outline.step.1.body"/>
                    </v-col>
                </v-row>
                <v-row :class="[{'flex-column-reverse':$vuetify.breakpoint.width<=768}, {'text-center':$vuetify.breakpoint.width<=616}]">
                    <v-col>
                        <h2><Str index="home.outline.step.2.title"/></h2>
                        <Str index="home.outline.step.2.body"/>
                    </v-col>
                    <v-col style="text-align: center;">
                        <div :class="['hex-box', 'custom-yellow', {'mx-auto':$vuetify.breakpoint.width<=616}]"  :style="{'background-image':`url('${image.Step2}')`}"/>
                    </v-col>
                </v-row>
                <v-row :class="[{'flex-column':$vuetify.breakpoint.width<=768}, {'text-center':$vuetify.breakpoint.width<=616}]">
                    <v-col style="text-align: center;">
                        <div :class="['hex-box', 'custom-green', 'float-lg-right', {'mx-auto':$vuetify.breakpoint.width<=616}]"  :style="{'background-image':`url('${image.Step3}')`}"/>
                    </v-col>
                    <v-col>
                        <h2><Str index="home.outline.step.3.title"/></h2>
                        <Str index="home.outline.step.3.body"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp"/>
    </v-row>
</v-container>
</template>

<script>
import StartButton from './StartButton.vue'
import Step1 from './assets/ic-hexagon1.svg'
import Step2 from './assets/ic-hexagon2.svg'
import Step3 from './assets/ic-hexagon3.svg'
export default {
    components: {
        StartButton
    },
    computed: {
        image(){
            return {
                Step1,
                Step2,
                Step3
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.steps{
    background-color: rgb(232, 230, 230);
}

.hex-box{
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    width: 140px;
    height: 140px;
    background-size: contain;
}

.hex-box.custom-pink{
    // background-color: $pink;
}
.hex-box.custom-yellow{
    // background-color: $yellow;
}
.hex-box.custom-green{
    // background-color: $green;
}

h1{
    color: $gray;
    font-weight: bold;
    font-size: 4em;
    line-height: 1em;
}
@media only screen and (max-width: 600px) {
h1 {
    font-size: 3.3em;
}
}

p{
    margin-top: 25px;
    margin-bottom: 25px;
}
</style>
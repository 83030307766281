<template>
    <div>
        <template v-if="hs">
            <v-radio-group v-model="test.video">
                <v-radio v-for="video in _videos" :key="`video-${video.value.id}`" :label="video.text" :value="video.value"/>
            </v-radio-group>
            <v-text-field type="number" v-model="test.percentage" label="Percentage watched"/>
            <template v-if="test.video">
                <v-btn x-small @click="hs.program.search()">Hubspot -> Find Program</v-btn>
                <v-btn x-small @click="hs.program.create({video: test.video, percentage: test.percentage})">Hubspot -> Create Program</v-btn>
                <v-btn x-small @click="hs.program.update({video: test.video, percentage: test.percentage})">Hubspot -> Update Program</v-btn>
            </template>
            <hr>

            <v-btn x-small @click="hs.contact.search()">Hubspot -> Find User</v-btn>
            <v-btn x-small @click="hs.contact.create()">Hubspot -> Create User</v-btn>
            
            <hr>

            <v-btn x-small @click="hs.product.search()">Hubspot -> Find Product</v-btn>
            <v-btn x-small @click="hs.product.create()">Hubspot -> Create Product</v-btn>
            <v-btn x-small @click="hs.product.update({queued_credits:0, video_credits:0, pledge_credits:0, commitment_to_act: false, commitment_to_act_fup: false})">Hubspot -> Reset Update Product Via Update</v-btn>
            <hr>
        </template>
        <template v-if="user">
            <v-text-field v-model="buffer.jwt" label="JWT"/>
            <v-btn x-small @click="buffer.jwt = user.signInUserSession.idToken.jwtToken">Copy</v-btn>
            <v-btn x-small v-if="buffer.jwt" @click="user.signInUserSession.idToken.jwtToken = buffer">Apply JWT</v-btn>
        </template>
        <span v-if="debug" style="font-size: 8pt;">
            Session Expires: {{ session.expires }}<br>
            Seconds Left: {{ session.time_left }}s <br>
            Minutes Left: {{ session.time_left/60 }}<br>
            Inactivity: {{ session.inactivity }}s<br>
        </span>
        <Profile v-if="authenticated && $store.getters.LabelController" :user="user" @ready="setProfile"/>
    </div>
  </template>
  <script>
  import Profile from '@/components/Profile/Profile.vue'
  import { Amplify, Auth, Hub } from 'aws-amplify';
  import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
  import awsconfig from './UserController/config.js';
  
  Amplify.configure({
      Auth: awsconfig.Auth
  });
  
  // You can get the current config object
  const currentConfig = Auth.configure();
  const hs = function(component){
    let hs = this

    this.data = {
        hubspot_id: undefined,
        product_id: undefined,
        busy: false,
        timeouts: {
            product: null
        }
    }

    // this.data = ()=>{return data}

    this.sync = async(user)=>{
        if (component.$store.getters.accreditation_expired===true){
            console.log("HS Decoupled")
            return
        }
        
        if(user.attributes['custom:hubspot_id']===undefined || user.attributes['custom:product_id']===undefined){
            let hsUserResponse = await hs.contact.create(user.attributes.email)
            hs.data.hubspot_id = hsUserResponse.status==200 ? hsUserResponse.data.contact.id : undefined
            
            let productResponse = await hs.product.create(hs.data.hubspot_id)
            hs.data.product_id = productResponse.status==200 ? productResponse.data.product.id : undefined

            component.updateAttribute({
                'custom:hubspot_id': hsUserResponse.data.contact.id,
                'custom:product_id': productResponse.data.product.id
            })
        }else{
            hs.data.hubspot_id = user.attributes['custom:hubspot_id']
            hs.data.product_id = user.attributes['custom:product_id']
        }
    }

    this.contact = {
        search: async (email)=>{
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }            
            
            try {
                let response = await component.sendRequest('put','/api/hs/contact',{email})
                return response
            } catch (error) {
                console.error(error)
                return error
            }
        },
        create: async (email)=>{           
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }
            
            try {
                let language = component.language
                let response = await component.sendRequest('post','/api/hs/contact',{email, language})
                return response
            } catch (error) {
                console.error(error)
                return error
            }
        },
        update: async ({email, properties})=>{
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }
            
            try {
                let response = await component.sendRequest('patch','/api/hs/contact',{email, properties})
                return response
            } catch (error) {
                console.error(error)
            }
        },
        transformCognitoFields: (data)=>{
            let map = {
                given_name: 'firstname',
                family_name: 'lastname',
                email: 'email',
                contact_type: 'contact_type',
                'custom:country': 'country',
                'custom:province': 'province',
                'custom:profession': 'hcp_job_profession',
                'custom:specialty': 'hcp_specialty',
                'custom:preferred_language': 'hs_language',
                'custom:postal_code': 'zip'
            }

            let profileItems = {
                given_name: 'firstname',
                family_name: 'lastname',
                'custom:country': 'country',
                'custom:province': 'province',
                'custom:profession': 'hcp_job_profession',
                'custom:specialty': 'hcp_specialty',
                'custom:preferred_language': 'hs_language',
                'custom:postal_code': 'zip'
            }

            let output = {}
            //To remove missing items from hubspot
            for(let key in profileItems){
                output[map[key]] = data[key] ? data[key] : null
            }
            for(let key in data){
                if(map[key]){
                    output[map[key]] = data[key]
                }
            }

            return output
        }
    }

    this.product = {
        search: async ()=>{
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }
            
            let hubspot_id = hs.data.hubspot_id
            try {
                let response = await component.sendRequest('put','/api/hs/product',{hubspot_id})
                return response
            } catch (error) {
                console.error(error)
                return error
            }
        },
        create: async (properties)=>{
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }
            
            let hubspot_id = hs.data.hubspot_id
            try {
                let response = await component.sendRequest('post','/api/hs/product',{hubspot_id,properties})
                return response
            } catch (error) {
                console.error(error)
                return error
            }
        },
        update: async (properties)=>{
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }
            
            async function update(){
                return new Promise(async (resolve, reject)=>{
                    hs.data.busy = true
                    let hubspot_id = hs.data.hubspot_id
                    try {
                        let response = await component.sendRequest('patch','/api/hs/product',{hubspot_id,properties})
                        resolve(response)
                    } catch (error) {
                        console.error(error)
                        reject(error)
                    }
                    hs.data.busy = false
                })
            }
            clearTimeout(hs.data.timeouts.product)
            hs.data.timeouts.product = setTimeout(async () => {
                if(component.user){
                    return await update()
                }
            }, 1000);
        }
    }

    this.program = {
        search: async ()=>{
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }
            
            let hubspot_id = hs.data.hubspot_id
            try {
                let response = await component.sendRequest('put','/api/hs/program',{hubspot_id, program: component.test.video})
                console.log(response)
            } catch (error) {
                console.error(error)
            }
        },
        create: async ()=>{
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }
            
            let hubspot_id = hs.data.hubspot_id
            try {
                let program = component.test.video
                let packet = {hubspot_id, program}
                let response = await component.sendRequest('post','/api/hs/program',packet)
                console.log(response)
            } catch (error) {
                console.error(error)
            }
        },
        update: async ({video, percentage, summary_download})=>{
            if (component.$store.getters.accreditation_expired===true){
                console.log("HS Decoupled")
                return
            }
            
            async function update(){
                return new Promise(async (resolve, reject)=>{
                    hs.data.busy = true
                    let hubspot_id = hs.data.hubspot_id
                    let product_id = hs.data.product_id
                    try {
                        let program = JSON.stringify(video)
                        program = JSON.parse(program)
                        if(percentage!=undefined){program.watched = percentage ? percentage : 0}
                        if(summary_download!=undefined){program.summary_download = program.status.summary_download}
                        let packet = {hubspot_id, product_id, program}
                        let response =  await component.sendRequest('patch','/api/hs/program',packet)
                        video.hubspot_program_id = response.data.program.id
                        resolve(response)
                    } catch (error) {
                        console.error(error)
                        reject(error)
                    }
                    hs.data.busy = false
                })
            }

            return await update()

        }
    }

  }
  export default {
    components: {
        Profile
    },
    name: "UserController",
    created: async function(){
        await this.findUser()
        let self = this
        Hub.listen('auth', async(data) => {
            if(self.debug){
                console.log('Hub response',{
                    event: data.payload.event,
                    data
                })
            }
            const { payload } = data;
            self.hubEvent = payload

            if(payload.event=='autoSignIn'){
                const user = payload.data
                self.user = user
            }

            if(payload.event=='signIn'){
                self.findUser()
            }else if(payload.event=='signIn_failure'){
                if(payload.data.code=='UserNotConfirmedException'){

                }
            }

            if(payload.event=='signOut'){
                self.findUser()
            }else if(payload.event=='signOut_failure'){

            }

            if(payload.event=='signUp'){

            }else if(payload.event=='signUp_failure'){
                /*
                if(payload.data.code=="UsernameExistsException"){
                    await self.resendConfirmationCode(self.temp.email)
                }
                */
            }else{

            }
        })
        this.hs = new hs(this)
        this.$store.commit('UserController',this)

        
        let elem = document.getElementById('app')
        elem.addEventListener('click',function(event){
            self.session.inactivity = 0
        })
        window.addEventListener('scroll',function(event){
            self.session.inactivity = 0
        })
        window.addEventListener('keydown',function(event){
            self.session.inactivity = 0
        })
        self.trackInactivity()
        self.sessionHandler()
  
    },
    data: function(){
        return {
            test: {
                video: null,
                percentage: 0
            },
            user: undefined,
            hubEvent: null,
            session: {
                expires: null,
                time_left: null,
                inactivity: 0,
                inactivity_timer: null
            },
            temp: {
                user: null,
                email: null
            },
            profile: undefined,
            hs: null,
            buffer: {
                jwt: null
            }
        }
    },
    methods: {
        setProfile(component){
            this.profile = component
        },
        signIn: async function({email, password}, onSuccess){
            Auth.configure({
                authenticationFlowType: 'USER_SRP_AUTH'
            })

            try {
                this.user = await Auth.signIn(email, password);
                let user_id = this.user.attributes.sub
                let first_name = this.user.attributes.given_name
                let last_name = this.user.attributes.family_name
                this.sendRequest('put','/api/log',{user_id, action:'signIn', parameters: {email, first_name, last_name}})
                if(onSuccess && typeof onSuccess=='function'){
                    onSuccess()
                }
                this.cache.storage.local.clear('pathAfterLogin')
                return true
            } catch (err) {
                console.error(err)
                return false
            }
        },
        requestOTP: async function(username){
            let self = this
            Auth.configure({
                authenticationFlowType: 'CUSTOM_AUTH'
            })

            try {
                let response = await Auth.signIn(username).then(user=>{
                    self.temp.user = user
                    return true
                }).catch(function(err){
                    console.log(err)
                    return false
                });
                console.log('authorizer > requestOTP', response)
            } catch (err) {
                console.log('error signing in', err);
                return false
            }
            return true
        },
        signInOTP: async function(challengeResponse){
            let self = this
            Auth.configure({
                authenticationFlowType: 'CUSTOM_AUTH'
            })
            try {
                Auth.sendCustomChallengeAnswer(self.temp.user, challengeResponse)
                .then((user) => {
                    let email = user.attributes.email
                    let user_id = user.attributes.sub
                    let first_name = user.attributes.given_name
                    let last_name = user.attributes.family_name
                    self.sendRequest('put','/api/log',{user_id, action:'signIn_OTP', parameters: {email, first_name, last_name}})
                    return user
                })
                .catch((err) => {
                    return err
                })
            } catch (err) {
                return err
            }
        },
        async requestEmailChange(email){
            let user = await Auth.currentAuthenticatedUser();
            this.temp.user = user
            try{
                let result = await Auth.updateUserAttributes(user, {
                    email
                });
                console.log('requestEmailChange',result)
                return true
            }catch(err){
                console.error('Error requestEmailChange',err)
                return false
            }
        },
        async verifyEmailChange(pin){
            try{
                let result = await Auth.verifyCurrentUserAttributeSubmit('email', pin)
                await this.findUser()
                console.log('verifyEmailChange',result)
                return true
            }catch(err){
                console.error('Error verifyEmailChange',err)
                return false
            }
        },
        async signUp({email, password, attributes}){
            this.temp.email = email
            try{                
                this.user = await Auth.signUp({
                    username: email, 
                    password,
                    attributes,
                    autoSignIn: { // optional - enables auto sign in after user is confirmed
                        enabled: true,
                    }
                })
                this.cache.storage.local.set('signUp',this.user)
                return true
            }catch(err){
                // console.error('signUp error',err)
                if (err.name==='UsernameExistsException'){
                    let response = await this.resendConfirmationCode(email)
                    if (response!==false){
                        return true
                    }
                }
                else {
                    return false
                }
            }
        },
        async signOut() {
            try {
                let email = this.user.attributes.email
                let user_id = this.user.attributes.sub
                let first_name = this.user.attributes.given_name
                let last_name = this.user.attributes.family_name
                await this.sendRequest('put','/api/log',{user_id, action:'signOut', parameters: {email, first_name, last_name}})
                
                let result = await Auth.signOut();
                this.$router.push({path:'/'})
                return this.hubEvent
            } catch (err) {
                console.log(err)
                return this.hubEvent
            }
        },
        async resendConfirmationCode(username) {
            try {
                let response = await Auth.resendSignUp(username);
                console.log('code resent successfully');
                return response
            } catch (err) {
                // console.error('error resending code: ', err);
                return false
            }
        },
        async forgotPassword(username){
            try {
                let response = await Auth.forgotPassword(username)
                console.log({response, hubEvent: this.hubEvent})
                return response
            } catch (err) {
                console.log({err, hubEvent: this.hubEvent})
                return err
            }
        },
        async resetPassword(username, code, password){
            try {
                let response = await Auth.forgotPasswordSubmit(username, code, password)
                console.log('resetPassword', response)
                return response
            } catch (err) {
                console.log({err, hubEvent: this.hubEvent})
                return err
            }
        },
        async confirmSignUp({username, verificationCode}){
            try {
                this.user = await Auth.confirmSignUp(username, verificationCode);
                return true
            } catch (err) {
                console.error(err)
                return false
            }
        },
        async findUser(){
            let self = this
            let user
            try{
                user = await Auth.currentAuthenticatedUser()
                if(user){
                    this.cache.storage.local.clear('signUp')
                    this.cache.storage.local.clear('pathAfterLogin')
                }
            }catch(err){
                let user = this.cache.storage.local.get('signUp')
                if(user){
                    console.log('Restoring signup')
                    this.user = user
                }                
            }
            self.user = user
        },
        async federation(idp){
            try{
                let result = Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider[idp]})
                return result
            }catch(err){
                return err
            }

        },
        async updateAttribute(data){
            if(data){
                try{
                    let user = await Auth.currentAuthenticatedUser();

                    try{
                        let result = await Auth.updateUserAttributes(user, data);
                        let email = user.attributes.email
                        let properties = this.hs.contact.transformCognitoFields(data)
                        await this.hs.contact.update({email, properties})
                        await this.findUser()
                        return result
                    }catch(err){
                        return err
                    }
                }catch(err){
                    return err
                }
            }
        },
        async sessionHandler(){
            let self = this
            let user = self.user
            clearTimeout(self.session.timer)
            if(user){
                self.session.expires = new Date(user.signInUserSession.accessToken.payload.exp*1000)

                let t = new Date()
                let time_left = (self.session.expires.getTime() - t.getTime()) / 1000
                self.session.time_left = time_left

                if(time_left<=5){
                    if(self.session.inactivity>1800){
                        console.log('Signing out')
                        await self.signOut()
                    }else{
                        console.log('Restarting session')
                        await self.refreshSession()
                        await self.findUser()
                    }
                }


                self.session.timer = setTimeout(function(){
                    self.sessionHandler()
                },(1000))
            }else{
                self.session.expires = null
                self.session.time_left = null
            }
        },
        trackInactivity: function(){
            let self = this

            if(self.$store.getters.controllers.VideoController && self.$store.getters.controllers.VideoController.playerState!='playing'){
                self.session.inactivity++
            }
            
            clearTimeout(self.session.inactivity_timer)            
            self.session.inactivity_timer = setTimeout(function(){
                if(self.user){
                    self.trackInactivity()
                }
            },1000)
        },
        authorized: function(groups=[]){
            if(groups.length==0 && this.user){
                return true
            }

            let userAccess = this.user && this.user.signInUserSession && this.user.signInUserSession.accessToken.payload['cognito:groups'] ? this.user.signInUserSession.accessToken.payload['cognito:groups'] : []
            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(userAccess.includes(group)){
                    return true
                }
            }

            return false
        },
        async refreshSession(){
            let response = await Auth.currentSession()
            console.log('refreshSession', response)
        }

    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        authenticated(){
            return (typeof this.user=='object') && (typeof this.user.signInUserSession=='object')
        },
        language(){
            return this.$store.getters.LanguageController.language
        },
        _videos(){
            let output = []
            let base = `${location.protocol}//${location.host}`
            let VideoController = this.$store.getters.VideoController
            let language = this.language
            if(VideoController){
                let videos = VideoController.videos
                for(let i=0; i<videos.length; i++){
                    let video = videos[i]
                    let id = video.id
                    let watched = video.status.played['en-ca'] > video.status.played['fr-ca'] ? video.status.played['en-ca'] : video.status.played['en-ca'] < video.status.played['fr-ca'] ? video.status.played['fr-ca'] : 0
                    let summary_download = false
                    let summary_url = `${base}/summary_download/${video.id}`
                    let video_url = `${base}/${this.$router.getRoutes().filter((item)=>{return item.name=='Educational Videos'})[0].path}/${video.id}`
                    output.push({
                        text: video.title[language],
                        value: {
                            id,
                            watched,
                            summary_download,
                            summary_url,
                            video_url
                        }
                    })
                }

            }

            return output
        }
    },
    watch: {
        async user(){
            if(this.authenticated){
                let self = this

                
                if(this.session.expires===null){
                    this.trackInactivity()
                    this.sessionHandler()
                }

                setTimeout(async() => {
                    self.hs.sync(self.user)                    
                    try {
                        let response = await self.sendRequest('get','/api/lastLogin')
                        if(response.data.length>0){
                            let date = new Date(response.data[0].timestamp)
                            self.$store.commit('lastLogin', date)
                        }else{
                            self.$store.commit('lastLogin',new Date())
                        }
                    } catch (error) {
                        console.error(error)
                    }
                }, 200);
            }
            this.temp.user = null
            this.temp.email = null
        }
    }
  }
  </script>
  
  <style lang='scss'>
  
  #MoreInfo {
      width: calc(100% + 40px);
      position: fixed;
      top: 52px;
      left: -20px;
      background-color: rgba(0,0,0,0.2);
      z-index: 1;
  }
  </style>
  
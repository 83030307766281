<template>
  <div v-if="$store.getters.UserController.authorized(['admin','staff','tester'])">
    <v-btn :disabled="busy" x-small @click="getVideos">getVideos</v-btn>
    <v-btn :disabled="busy" x-small @click="resetAllVideos">Reset all videos</v-btn>
    <v-btn :disabled="busy" x-small @click="finishRandomVideos">Finish RANDOM videos</v-btn>
    <v-btn :disabled="busy" x-small @click="finishAllVideos">Finish ALL videos</v-btn>
    <v-btn :disabled="busy" x-small @click="randomizeLikeCount">Randomize total like count</v-btn>
    <v-btn :disabled="busy" x-small @click="likeRandomVideos">Like Random Videos</v-btn>   
    <v-btn :disabled="busy" x-small @click="ui.playerError = !ui.playerError">Toggle Player Error</v-btn>
    <v-dialog v-model="ui.playerError" :persistent="true" width="600">
        <v-card>
            <v-card-title>
                <span v-html="msg.title"/>
            </v-card-title>
            <v-card-text>
                <span v-html="msg.body"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" @click="dismissPlayerError">
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>

</template>

<script>
export default {
    name: 'VideoController',
    async created(){
        await this.init()
        this.routeSelection()
        this.$store.commit('VideoController',this)
    },
    data: function(){
        return {
            initialized: false,
            selected: undefined,
            videos: [],
            routeSelectionTimer: null,
            routeSelectionCounter: 0,
            busy: false,
            timers: {
                gotoVideo: null,
                downloadSummary: null,
                setFinished: null
            },
            playerState: 'idle',
            ui: {
                playerError: false
            }
        }
    },
    methods: {
        async init(){
            this.initialized = false
            this.selected = undefined
            this.videos = this.authenticated ? await this.getVideos() : []
            this.initialized = true
            return "init-videos-complete"
        },
        async getVideos(){
            let self = this
            function getRandomInt(max) {
               return Math.floor(Math.random() * max)+5;
            }            
            async function template(id, started, finished, liked, duration){
                // let thumbnail_en = await self.sendRequest('post','/api/assets',{fileName:`videos/en-ca/thumbnails/video_${id}.png`})
                // let thumbnail_fr = await self.sendRequest('post','/api/assets',{fileName:`videos/fr-ca/thumbnails/video_${id}.png`})
                return {
                    id,
                    title: {
                        'en-ca': `Video ID - ${id}`,
                        'fr-ca': `Video ID - ${id}`
                    },
                    thumbnail: {
                        'en-ca': `https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/thumbnails/en-ca/video_${id}.png`,
                        'fr-ca': `https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/thumbnails/fr-ca/${id}.png`
                    },
                    file: {
                        'en-ca': `videos/en-ca/video_${id}.mp4`,
                        'fr-ca': `videos/fr-ca/video_${id}.mp4`
                    },
                    description: {
                        'en-ca': `Fusce ullamcorper, est at posuere viverra, feliz leo suscipit ex, accumsan commodo urna tortor id elit. Duis sed elit sit amet erat blandit sollicitudin. Aliquam semper eros neque, in euismod mi euismod ac. Pellentesque et sagittis nunc, vitae feugiat mi. Donec at leo a purus porta lobortis vel vel nisi.`,
                        'fr-ca': `Fusce ullamcorper, est at posuere viverra, feliz leo suscipit ex, accumsan commodo urna tortor id elit. Duis sed elit sit amet erat blandit sollicitudin. Aliquam semper eros neque, in euismod mi euismod ac. Pellentesque et sagittis nunc, vitae feugiat mi. Donec at leo a purus porta lobortis vel vel nisi.`,
                    },
                    duration,
                    status: {
                        started,
                        finished,
                        liked,
                        played: {
                            'en-ca': 0,
                            'fr-ca': 0
                        },
                        summary_download: false
                    },
                    likes: 0,
                    credits: 0.25
                }
            }
            // let output = []
            // let config = {
            //     started: [],
            //     finished: [],
            //     liked: []
            // }
            // for(let id=1; id<=14; id++){
            //     let duration = {
            //         'en-ca': getRandomInt(10),
            //         'fr-ca': getRandomInt(10)
            //     }
            //     let record = await template(id, config.started.includes(id), config.finished.includes(id), config.liked.includes(id), duration)
            //     output.push(record)
            // }

            let output = []
            try {
                let response = await this.sendRequest('get','/api/videos')
                // let videos = response.data.videos
                output = response.data.videos
            } catch (error) {
                console.error('Error getting videos')
            }

            let sorted = output.sort((a,b)=>{ if(a.id<b.id){return -1} if(a.id>b.id){return 1} return 0 })
            let base = `${location.protocol}//${location.host}`
            for(let i=0; i<sorted.length; i++){
                let video = sorted[i]
                video.summary_url = `${base}/summary_download/${video.id}`
                video.video_url = `${base}${this.$router.getRoutes().filter((item)=>{return item.name=='Educational Videos'})[0].path}/${video.id}`
            }
            this.videos = sorted
            return sorted
        },
        gotoVideo(video){
            clearTimeout(this.timers.gotoVideo)
            let delay = this.UserController.hs.data.busy ? 2000 : 0
            let self = this
            this.timers.gotoVideo = setTimeout(() => {
                self.$router.push({name:'Video',params:{video_id:video.id}})                            
            }, delay);
        },
        routeSelection(){
            let video_id = this.video_id
            let selected = undefined
            if(video_id){
                clearTimeout(this.routeSelectionTimer)
                let videos = this.videos
                let self = this
                if(videos.length==0 && self.routeSelectionCounter<=3){
                    self.routeSelectionCounter++
                    self.routeSelectionTimer = setTimeout(() => {
                        self.routeSelection()
                    }, 1000);
                }else{
                    self.routeSelectionCounter = 0
                    let records = videos.filter((record)=>{return record.id==video_id})
                    if(records.length==1){
                        selected = records[0]
                    }else{
                        this.$router.push({name:'Educational Videos'})
                    }
                }
            }
            this.selected = selected
        },
        async downloadSummary({video, state}){
            let self = this
            let summaryUrl = `https://i-act-ca-assets.s3.ca-central-1.amazonaws.com/public/summaries/${this.$store.getters.LanguageController.language}/Module_${video.id}.pdf`
            window.open(summaryUrl, '_blank', 'noreferrer');
            clearTimeout(this.timers.downloadSummary)
            let delay = this.UserController.hs.data.busy ? 2000 : 0
            this.timers.downloadSummary = setTimeout(async () => {
                let targetIndex = self.getIndex(video)
                self.videos[targetIndex].status.summary_download = state!=undefined ? state : !self.videos[targetIndex].status.summary_download
                await self.UserController.hs.program.update({video, summary_download: self.videos[targetIndex].status.summary_download})
                await self.sendRequest('post','/api/video/summary_download',{video, state: self.videos[targetIndex].status.summary_download})                            
            }, delay);

        },
        getPreviousVideo(){
            let currentIndex = this.currentIndex()
            let videos = this.videos
            if(currentIndex>0){
                return videos[currentIndex-1]
            }
            return undefined
        },
        getNextVideo(){
            let currentIndex = this.currentIndex()
            let videos = this.videos

            let nextUnwatched = []
            for(let i=currentIndex+1; i<videos.length; i++){
                let video = videos[i]
                if(!video.status.finished){
                    nextUnwatched.push(video)
                }
            }

            if(currentIndex<videos.length-1){
                for(let i=currentIndex+1; i<videos.length; i++){
                    let video = videos[i]
                    if(!video.status.finished || nextUnwatched.length==0){
                        return video
                    }
                }
            }
            return undefined
        },
        getIndex(video){
            let videos = this.videos
            return videos.map(function(e) { return e.id }).indexOf(video.id)
        },
        currentIndex(){
            let selected = this.selected
            if(selected){
                return this.getIndex(selected)
            }
            return 0
        },
        player_idle(){
            this.playerState = 'idle'
        },
        player_pause(){
            this.playerState = 'paused'
        },
        player_play(){
            this.playerState = 'playing'
        },
        player_end(){
            this.playerState = 'end'
        },
        player_seek(){
            this.playerState = 'seek'
        },
        async setLiked({video, state, forceUser}){
            let targetIndex = this.getIndex(video)
            this.videos[targetIndex].status.liked = state!=undefined ? state : !this.videos[targetIndex].status.liked
            if(this.videos[targetIndex].status.liked){
                this.videos[targetIndex].likes++
            }else if(this.videos[targetIndex].likes>0){
                this.videos[targetIndex].likes--
            }


            let response
            try {
                response = await this.sendRequest('put','/api/video/like',{video, language: this.$store.getters.LanguageController.language, forceUser})
            } catch (error) {
                this.errorHandler('VideoController > setLiked',error)
            }            
        },
        async setStarted({video, state, language}){
            let targetIndex = this.getIndex(video)

            if(this.videos[targetIndex].status.started!=state){
                this.videos[targetIndex].status.started = state!=undefined ? state : !this.videos[targetIndex].status.started
                try {
                    await this.sendRequest('post',`/api/video/started`,{video, language})
                } catch (error) {
                    this.errorHandler('VideoController > setStarted',error)
                }
            }
        },
        async setPlayed({video, language, percentage, forced=false}){
            let targetIndex = this.getIndex(video)
            let hsThresholds = [1, 10, 20, 30, 40, 50, 60, 70, 80, 90]
            if(forced || percentage > this.videos[targetIndex].status.played[language]){
                this.videos[targetIndex].status.played[language] = percentage
                if(forced || hsThresholds.includes(percentage)){
                    let response = await this.UserController.hs.program.update({video, percentage})
                    // video.hubspot_program_id = response.data.program.id

                }
                try {
                    await this.sendRequest('patch','/api/video/played',{video, percentage, language})
                } catch (error) {
                    this.errorHandler('VideoController > setPlayed',error)
                }
            }
        },
        async setFinished({video, state, forced=false}){
            let self = this
            clearTimeout(this.timers.setFinished)
            let delay = this.UserController.hs.data.busy ? 2000 : 0
            this.timers.setFinished = setTimeout(async () => {
                let targetIndex = self.getIndex(video)
                self.videos[targetIndex].status.finished = state!=undefined ? state : !self.videos[targetIndex].status.finished
                if (state!==undefined && state===true){
                    self.videos[targetIndex][`${self.language}_views`]++
                }
                try {
                    await self.UserController.hs.program.update({video, percentage: self.videos[targetIndex].status.finished ? 100 : 0})
                } catch (error) {
                    this.errorHandler('VideoController > setFinished',error)
                }
                self.sendRequest('patch','/api/video/finished',{video, state: self.videos[targetIndex].status.finished, language: self.language})                
            }, delay);


        },
        async resetAllVideos(){
            async function reset({video, language, percentage, forced, state, baseDelay}){
                setTimeout(async()=>{
                    await self.setLiked({video, state})
                },baseDelay+2000)
                setTimeout(async()=>{
                    await self.setStarted({video, state})
                },baseDelay+4000)
                setTimeout(async()=>{
                    await self.setPlayed({video, language, percentage, forced})
                },baseDelay+6000)
                setTimeout(async()=>{
                    await self.setFinished({video, state, forced})
                },baseDelay+8000)
            }

            let self = this
            let baseDelay = 0

            let videos = self.videos
            let language = self.language
            let percentage = 0
            let state = false
            let forced = true
            self.busy = true
            for(let i=0; i<videos.length; i++){
                let video = videos[i] 
                video.status.summary_download = false
                baseDelay+=2000
                reset({video, language, percentage, forced, state, baseDelay})
                if(i==videos.length-1){
                    setTimeout(async () => {
                        await self.getVideos()
                        self.busy = false
                    }, baseDelay);
                }
            }
            
        },
        async finishAllVideos(){
            let videos = this.videos
            let state = true
            for(let i=0; i<videos.length; i++){
                let video = videos[i] 
                await this.setFinished({video, state})
            }
        },
        async finishRandomVideos(){
            function getRandomInt(max) {
               return Math.floor(Math.random() * max);
            }
            let videos = this.videos
            for(let i=0; i<videos.length; i++){
                let video = videos[i] 
                let shouldFinish = getRandomInt(10)<=4
                await this.setFinished({video, shouldFinish})
            }

        },
        async likeRandomVideos(randomUser){
            function getRandomInt(max) {
               return Math.floor(Math.random() * max);
            }
            let videos = this.videos
            for(let i=0; i<videos.length; i++){
                let video = videos[i] 
                let shouldLike = getRandomInt(10)<=4
                if(randomUser){
                    let forceUser = getRandomInt(100)+1
                    await this.setLiked({video, shouldLike, forceUser})
                }else{
                    await this.setLiked({video, shouldLike})
                }
                
            }

        },
        async randomizeLikeCount(){
            let randomUser = true
            for (let index = 0; index < 250; index++) {
                this.likeRandomVideos(randomUser)
            }
        },
        errorHandler(description, error){
            console.error('errorHandler',{description, error})
            this.ui.playerError = true
            let player = document.getElementById('videoPlayer')
            if(player){
                player.pause()
            }
        },
        dismissPlayerError(){
            this.ui.playerError=false; 
            window.location.reload()
        }
    },
    computed: {
        msg(){
            if(this.$store.getters.LanguageController.language=='fr-ca'){
                return {
                    title: "Désolé ! Une erreur s'est produite lors de la lecture vidéo.",
                    body: "<p>Veuillez vous déconnecter de votre compte et vous reconnecter pour continuer à suivre vos progrès.</p><p>Pour capturer la progression de votre vidéo, veuillez la regarder à partir d'un navigateur, d'un onglet et d'un appareil à la fois.</p> <p>[Notez que regarder à partir de plusieurs appareils ou navigateurs peut entraîner le non-suivi de l'achèvement de la vidéo.]</p>"
                }                
            }
            return {
                title: "Sorry! There was an error with video playback.",
                body: "<p>Please log out of your account and sign back in to continue tracking your progress.</p><p>To capture your video progress, please watch from one browser, tab and device at a time.</p><p>[Note that watching from multiple devices or browsers could result in video completion not being tracked.]</p>"
            }
        },
        video_id(){
            return this.$route.params.video_id
        },
        allWatched(){
            let videos = this.videos
            let output = true
            for(let i=0; i<videos.length; i++){
                let video = videos[i]
                if(!video.status.finished){
                    output = false
                    break;
                }
            }

            return output
        },
        watched(){
            let output = []
            let videos = this.videos
            for (let index = 0; index < videos.length; index++) {
                const video = videos[index];
                if(video.status.finished){
                    output.push(video)
                }
            }

            return output
        },
        recommendedVideos(){
            let videos = JSON.stringify(this.videos)
            videos = JSON.parse(videos)
            let sorted = videos.sort((a,b)=>{ if(a.likes>b.likes){return -1} if(a.likes<b.likes){return 1} return 0 })
            if(this.allWatched){
                return sorted
            }else{
                let unwatched = []
                for (let index = 0; index < sorted.length; index++) {
                    const element = sorted[index];
                    if(!element.status.finished){
                        unwatched.push(element)
                    }
                }
                return unwatched
            }
            
        },
        mostLiked(){
            let videos = JSON.stringify(this.videos)
            videos = JSON.parse(videos)
            let sorted = videos.sort((a,b)=>{ if(a.likes>b.likes){return -1} if(a.likes<b.likes){return 1} return 0 })
            return sorted            
        },
        mostViewed(){
            let videos = JSON.stringify(this.videos)
            videos = JSON.parse(videos)
            videos = videos.filter(video => video[`${this.language}_views`]>0)
            let sorted = videos.sort((a,b)=>{ if(a[`${this.language}_views`]>b[`${this.language}_views`]){return -1} if(a[`${this.language}_views`]<b[`${this.language}_views`]){return 1} return 0 })
            return sorted            
        },
        language(){
            return this.$store.getters.LanguageController.language
        },
        authenticated(){
            return this.UserController.authenticated
        },
        UserController(){
            return this.$store.getters.UserController
        }
    },
    watch: {
        video_id(){
            this.routeSelection()
        },
        authenticated(){
            this.init()
        }
    }
}
</script>

<style>

</style>
<template>
  
</template>

<script>
export default {
    name: "LanguageController",
    created(){
        this.init()
        this.$store.commit('LanguageController',this)
    },
    data(){
        return {
            language: null
        }
    },
    methods: {
        init: function(){
            function returnMap(array, index){
                let output = {}

                for(let i=0; i<array.length; i++){
                    let item = array[i]
                    output[item[index]] = item
                }


                return output
            }

            let queryLanguage = this.$route.query.language ? this.$route.query.language : false
            let cookieLanguage = this.cache.storage.local.get('language')
            let browserLanguage = navigator.userLanguage || navigator.language;
            let browserLangPrimaryCode = browserLanguage.toLowerCase().split('-')[0]

            this.language = queryLanguage ? queryLanguage : cookieLanguage ? cookieLanguage : browserLangPrimaryCode && returnMap(this.languages,'value') ? `${browserLangPrimaryCode}-ca` : this.language         
        },
        setLanguage(lang){
            let index = this.languages.findIndex((item)=>{return item.value==lang})
            if(index>=0){
                this.language = lang
            }else{
                console.error('LanguageController',{error: 'Language not found'})
            }
        }
    },
    computed: {
        languages: function(){
            return [
                {text:'English',value:'en-ca'},
                {text:'Français',value:'fr-ca'}
            ]
        }
    },
    watch:{
        language(){
            this.cache.storage.local.set('language',this.language)
        }
    }
}
</script>

<style>

</style>
<template>
    <v-select class="custom-select" dark dense :items="languages" v-model="lang" @change="updateLanguage"/>
</template>

<script>
export default {
    created(){
        let language = this.LanguageController.language
        this.lang = language
    },
    data(){
        return {
            lang: null
        }
    },
    methods:{
        updateLanguage(){
            this.LanguageController.setLanguage(this.lang)
        }
    },
    computed:{
        LanguageController(){
            return this.$store.getters.LanguageController
        },
        language(){
            return this.LanguageController.language
        },
        languages(){
            return this.LanguageController.languages
        }
    },
    watch:{
        language(){
            this.lang = this.language
        }
    }
}
</script>

<style lang="scss">
.custom-select{
    position: relative;
    top: 8px;
}
.custom-select .v-select__selection{
    font-weight: bold;
}
.custom-select.v-text-field>.v-input__control>.v-input__slot:before { 
     border-style: none; 
} 
.custom-select.v-text-field>.v-input__control>.v-input__slot:after { 
     border-style: none; 
}
.custom-select .v-icon{
    color: $yellow;
}
.custom-select i.v-icon{
    font-size: 24pt;
}
@media #{map-get($display-breakpoints, 'sm-and-down')}{
.custom-select{
    font-size:12px;
}
}
</style>
<template>
  <v-container class="experts" fluid>
    <v-row>
        <v-col style="text-align: center;">
            <h1><Str index="home.experts.title"/></h1>
        </v-col>
    </v-row>
    <v-row align-content="center">
        <v-col cols="2" v-if="$vuetify.breakpoint.lgAndUp"/>
        <v-col>
            <v-container fluid>
                <v-row>
                    <v-col v-for="(expert, index) in experts" :key="`expert-${index}`" style="text-align: center;">
                        <Expert style="text-align: left;" class="expert" :expert="expert"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col cols="2" v-if="$vuetify.breakpoint.lgAndUp"/>
    </v-row>
  </v-container>
</template>

<script>
import Expert from './Experts/card.vue'
import JeffreyHabert from './assets/team_JeffreyHabert.jpg'
import IsabelleGagnon from './assets/team_IsabelleGagnon.jpg'
import SabrinaKwon from './assets/team_SabrinaKwon.jpg'
import SonjaWicklum  from './assets/team_SonjaWicklum.jpg'
import StephenGlazer  from './assets/team_StephenGlazer.jpg'
import MarieChantalMenard  from './assets/team_MarieChantalMenard.jpg'


export default {
    components: {
        Expert
    },
    computed: {
        experts(){
            let output = [
                {
                    name: 'Jeffrey Habert',
                    image: JeffreyHabert,
                    credentials: {
                        'en-ca': 'MD, CCFP, FCFP',
                        'fr-ca': 'MD, CCFP, FCFP'
                    },
                    details: {
                        'en-ca': `
                            <ul>
                                <li>Family Physician</li>
                                <li>Assistant Professor, Department of Family and Community Medicine, University of Toronto </li>
                                <li>Investigating Coroner, City of Toronto </li>
                                <li>Toronto, Ontario</li>
                            </ul>
                        `,
                        'fr-ca': `
                            <ul>
                                <li>Médecin de famille</li>
                                <li>Professeur adjoint, Département de médecine familiale et communautaire, Université de Toronto</li>
                                <li>Enquête du Coroner, ville de Toronto</li>
                                <li>Toronto (Ontario)</li>
                            </ul>
                        `,
                    }
                },
                {
                    name: 'Isabelle Gagnon',
                    image: IsabelleGagnon,
                    credentials: {
                        'en-ca': 'MD, CCSC, LMCC, FCPP',
                        'fr-ca': 'MD, CCSC, LMCC, FCPP'
                    },
                    details: {
                        'en-ca': `
                            <ul>
                            <li>Bariatric Medicine Medical Director, Weight Wise, Yukon Program </li>
                            <li>Whitehorse, Yukon </li>    
                            </ul>
                        `,
                        'fr-ca': `
                            <ul>
                            <li>Directrice médicale, médecine bariatrique, programme Weight Wise, Yukon</li>
                            <li>Whitehorse (Yukon)</li>    
                            </ul>                             
                        `,
                    }
                },
                {
                    name: 'Stephen Glazer',
                    image: StephenGlazer,
                    credentials: {
                        'en-ca': 'MD, FRCPC, FCCP, FAASM ',
                        'fr-ca': 'MD, FRCPC, FCCP, FAASM '
                    },
                    details: {
                        'en-ca': `
                            <ul>
                                <li>Diplomate of the American Board of Obesity Medicine</li>
                                <li>Medical Director of the Medical and Surgical Bariatric Program, Humber River Regional Hospital </li>
                                <li>President, The Canadian Association of Bariatric Physicians and Surgeons</li>
                                <li>Toronto, Ontario</li>
                            </ul>
                        `,
                        'fr-ca': `
                            <ul>
                                <li>Médecin agrégé, American Board of Obesity Medicine</li>
                                <li>Directeur médical du programme médical et chirurgical bariatrique, Humber River Regional Hospital</li>
                                <li>Président, L'Association canadienne des médecins et chirurgiens bariatriques</li>
                                <li>Toronto (Ontario)</li>
                            </ul>
                        `,
                    }
                },
                {
                    name: 'Sabrina Kwon',
                    image: SabrinaKwon,
                    credentials: {
                        'en-ca': 'MD, MCFP',
                        'fr-ca': 'MD, MCFP'
                    },
                    details: {
                        'en-ca': `
                            <ul>
                                <li>Diplomate of the American Board of Obesity Medicine </li>
                                <li>Medical Director, Alberta Obesity Centre North </li>
                                <li>Assistant Clinical Professor, Department of Family Medicine, University of Alberta</li>
                                <li>Physician, Edmonton Adult Bariatric Specialty Clinic </li>
                                <li>Edmonton, Alberta</li>
                            </ul>
                        `,
                        'fr-ca': `
                            <ul>
                                <li>Médecin agrégée, American Board of Obesity Medicine</li>
                                <li>Directrice médicale, Alberta Obesity Centre North</li>
                                <li>Professeure adjointe clinique, Département de médecine familiale, Université d’Alberta</li>
                                <li>Médecin, clinique de spécialités bariatriques pour adultes d’Edmonton</li>
                                <li>Edmonton (Alberta)</li>
                            </ul>
                        `,
                    }
                },
                {
                    name: 'Marie-Chantal Ménard ',
                    image: MarieChantalMenard,
                    credentials: {
                        'en-ca': 'MD',
                        'fr-ca': 'MD'
                    },
                    details: {
                        'en-ca': `
                            <ul>
                                <li>Family Physician</li>
                                <li>Saint-Jean-sur-Richelieu, Quebec </li>
                            </ul>
                        `,
                        'fr-ca': `
                            <ul>
                                <li>Médecin de famille</li>
                                <li>Saint-Jean-sur-Richelieu (Québec)</li>
                            </ul>
                        `,
                    }
                },
                {
                    name: 'Sonja Wicklum',
                    image: SonjaWicklum,
                    credentials: {
                        'en-ca': 'MD, FCFP',
                        'fr-ca': 'MD, FCFP'
                    },
                    details: {
                        'en-ca': `
                            <ul>
                                <li>Family Medicine Clerkship Director and Clinical Associate Professor, Cumming School of Medicine, University of Calgary</li>
                                <li>Calgary, Alberta</li>
                            </ul>
                        `,
                        'fr-ca': `
                            <ul>
                                <li>Directrice de commis, Médecine familiale et professeure agrégée de clinique, Cumming School of Medicine, Université de Calgary</li>
                                <li>Calgary (Alberta)</li>
                            </ul>
                        `,
                    }
                }
            ]

            return output
        }
    }
}

/*












*/
</script>

<style lang="scss" scoped>
.experts{
    background-color: whitesmoke;
}
h1{
    color: $blue;
    font-size: 4rem;
}
.expert{
    display: inline-block;
    max-width: 200px;
    min-width: 250px;
}

@media only screen and (max-width: 600px) {
h1 {
    font-size: 3.3em;
}
}
</style>
<template>
    <v-container fluid class="oc" id="oc-section">
        <v-row justify="center" class="white--text" style="position: relative; top: -90px;">
            <template v-if="$vuetify.breakpoint.width>=1200">
            <div class="dark1-wrapper" :style="{'background-image': `url('${image.dark1}')`}">
                        <div class="content white--text" style="padding: 15px 41px 15px 25px;">
                        <h1 class="mb-0 text-center">
                            <span v-if="$store.getters.LanguageController.language==='fr-ca'">26,6 %</span>
                            <span v-else>26.6%</span>                            
                        </h1>
                        <div class="content">
                            <Str index="home.oc.stats.a"/>
                        </div>                            
                        </div>
            </div>
            <div class="dark2-wrapper" :style="{'background-image': `url('${image.dark2}')`}">
                        <div class="content" style="padding: 15px 3px 15px 102px;">
                        <h1 class=" mb-0 text-center">
                            <span v-if="$store.getters.LanguageController.language==='fr-ca'">63,1 %</span>
                            <span v-else>63.1%</span>
                        </h1>
                        <div class="content pt-0">
                            <Str index="home.oc.stats.b"/>
                        </div>
                        </div>            
            </div>
            <div class="dark3-wrapper" :style="{'background-image': `url('${image.dark3}')`}">
                        <div class="content" style="padding: 15px 41px 15px 25px;">
                            <h1 class="white--text mb-0 text-center">6-14</h1>
                            <Str index="home.oc.stats.c"/>
                        </div>            
            </div>
            <div class="dark4-wrapper" :style="{'background-image': `url('${image.dark4}')`}">
                        <div class="content" style="padding: 15px 3px 15px 90px;">
                            <h1 class="white--text mb-0 text-center">
                                <span v-if="$store.getters.LanguageController.language==='fr-ca'">78 %</span>
                                <span v-else>78%</span>
                            </h1>
                            <Str index="home.oc.stats.d"/>
                        </div>
            </div>
            </template>
                        
            <template v-if="$vuetify.breakpoint.width<1200">
                <v-row justify="center" style="width:100%;">
                <div class="dark1-wrapper" :style="{'background-image': `url('${image.dark1}')`}">
                            <div class="content white--text" style="padding: 15px 41px 15px 25px;">
                                <h1 class="mb-0 text-center">
                                    <span v-if="$store.getters.LanguageController.language==='fr-ca'">26,6 %</span>
                                    <span v-else>26.6%</span>                            
                                </h1>
                                <div class="content">
                            <Str index="home.oc.stats.a"/>
                        </div>
                            </div>
                </div>
                <div class="dark2-wrapper" :style="{'background-image': `url('${image.dark2}')`}">
                            <div class="content" style="padding: 15px 3px 15px 102px;">
                                <h1>
                                    <span v-if="$store.getters.LanguageController.language==='fr-ca'">63,1 %</span>
                                    <span v-else>63.1%</span>
                                </h1>
                                <Str index="home.oc.stats.b"/>
                            </div>            
                </div>
                </v-row>
                <v-row justify="center" style="width:100%;">
                <div class="dark3-wrapper" :style="{'background-image': `url('${image.dark3}')`}">
                            <div class="content" style="padding: 15px 41px 15px 25px;">
                                <h1>6-14</h1>
                                <Str index="home.oc.stats.c"/>
                            </div>            
                </div>
                <div class="dark4-wrapper" :style="{'background-image': `url('${image.dark4}')`}">
                            <div class="content" style="padding: 15px 3px 15px 90px;">
                                <h1>
                                    <span v-if="$store.getters.LanguageController.language==='fr-ca'">78 %</span>
                                    <span v-else>78%</span>
                                </h1>
                                <Str index="home.oc.stats.d"/>
                            </div>                
                </div>
                </v-row>
            </template>
        </v-row>

        <v-row justify="center" id="oc-references" style="">
            <v-col class="d-flex justify-center" style="max-width:1098px;">
                <Str index="home.oc.stats.references"/>
            </v-col>
        </v-row>
        
        <v-container id="oc-about-wrapper" fluid fill-height>
        <v-row id="oc-about" class="align-center">
            <v-col id="oc-about-banner" :cols="$vuetify.breakpoint.width<1200 ? 12 : 6" class="pa-0">
                   <img :src="image.Banner" style="width:100%;position: relative;top: 4px;"/>
            </v-col>                    
            <v-col id="oc-about-content" :cols="$vuetify.breakpoint.width<1200 ? 12 : 6" class="px-0">
                <div id="content-wrapper">
                    <h1><Str index="home.oc.title"/></h1>
                    <p class="text-left"><Str index="home.oc.body"/></p>
                    <a target="_blank" href="https://obesitycanada.ca/">
                        <Str index="home.oc.button.visit obesity canada"/>
                    </a>
                </div>    
            </v-col>
        </v-row>
        </v-container>    
    </v-container>               
</template>

<script>
import Banner from './assets/obesityCanada-bg.jpg'
import dark1 from './assets/trap-dark-1.svg'
import dark2 from './assets/trap-dark-2.svg'
import dark3 from './assets/trap-dark-3.svg'
import dark4 from './assets/trap-dark-4.svg'
export default {
    computed: {
        image(){
            return {
                Banner,
                dark1,
                dark2,
                dark3,
                dark4                
            }
        }
    }
}
</script>

<style lang="scss" scoped>
h1{
    font-size: 4em;
    margin-bottom: 4%;
}
@media only screen and (max-width: 600px) {
h1 {
    font-size: 3.3em;
}
}
a{
    border: 3px solid gray !important;
    background-color: transparent !important;
    color: $gray !important;
}
.oc{
    background-color: $lightgray-1;
    color: $gray;
    font-weight: bold;
    background-size: cover;
    padding: 0;
}
.content{
    padding: 15px;
    color: white;
}
.dark1-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 100;
    left: 76px;
    top: -7px;
}
.dark2-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 75;
    left: 15px;
    top: -31px;
}
.dark3-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 50;
    left: -5px;
    top: -6px;
}
.dark4-wrapper {
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 25;
    top: -31px;
    left: -64px;
}
#oc-references {
    margin-bottom: 20px;
    position: relative; 
    top: -135px;
    font-size: 12px;
    font-weight: normal;
}
#oc-about {
    position: relative;
    top: -111px;
    background-color: $yellow;
}
#oc-about-content {
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;
    display: flex;
    
    #content-wrapper {
        width: 75%;
        text-align: center;
        
        p {
            margin-bottom: 8%;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .dark1-wrapper {
        top: 37px;
        left: 35px;
    }
    .dark2-wrapper {
        top: 20px;
        left: -30px;
    }
    .dark3-wrapper {
        z-index: 101;
        top: -40px;
        left: 17px;
    }
    .dark4-wrapper {
        z-index: 102;
        top:-62px;
        left:-47px;
    }
}

@media only screen and (max-width: 575px) {
    .dark1-wrapper {
        z-index: 25;
        left: 0;
    }
    .dark2-wrapper {
        z-index: 50;
        left: 0;
        top: -91px;
    }
    .dark3-wrapper {
        z-index: 75;
        left:-8px;
        top: -178px;
    }
    .dark4-wrapper {
        z-index: 100;
        left: 0;
        top: -286px;
    }
    #oc-references {
        top: -413px;
    }
    #oc-about-wrapper {
        max-height: 493px;
    }
    #oc-about {
        top: -419px;
    }
    #oc-about-content {   
        #content-wrapper {
            width: 90%;
            
            h1 {
                font-size: 2.3em;
            }
            
            p {
                font-size: 13px;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    #oc-about-wrapper {
        max-height: 471px;
    }
}


@media only screen and (max-width: 500px) {
    #oc-about-wrapper {
        max-height: 460px;
    }
}
@media only screen and (max-width: 480px) {
    #oc-about-wrapper {
        max-height: 454px;
    }
}
@media only screen and (max-width: 470px) {
    #oc-about-wrapper {
        max-height: 441px;
    }
}

@media only screen and (max-width: 450px) {
    #oc-about-wrapper {
        max-height: 420px;
    }
}
@media only screen and (max-width: 440px) {
    #oc-about-wrapper {
        max-height: 416px;
    }
}
@media only screen and (max-width: 430px) {
    #oc-about-wrapper {
        max-height: 408px;
    }
}

@media only screen and (max-width: 375px) {
    #oc-about-wrapper {
        max-height: 375px;
    }
}
@media only screen and (max-width: 340px) {
    #oc-about-wrapper {
        max-height: 375px;
    }
}

</style>
<template>
    <div v-if="$store.getters.UserController.authorized(['admin','staff','tester'])">
        <router-link :to="{name:'Campaign Admin'}">Admin</router-link>
        <v-btn x-small @click="storage().clear('campaign')">Clear Campaign Storage</v-btn>
    </div>
</template>

<script>
export default {
    async created(){
        await this.initTracking()
        this.$store.commit('CampaignController',this)
    },
    data(){
        return {
            routes: [],
            campaigns: [],
            logs: []
        }
    },
    methods: {
        async initTracking(){
            let campaign = this.campaign
            let record = this.storage().getCampaign()
            if(campaign){
                if(!record || (record && (record.cid!=campaign.cid || record.vanity!=campaign.vanity))){
                    await this.saveLog(campaign, 'page_load')
                }
                if(campaign.redirect){
                    this.$router.push({path:campaign.redirect})
                }
                this.storage().storeCampaign(campaign)
            }
        },
        async adminReady(){
            this.getRoutes()
            await this.getCampaigns()
        },
        campaignTemplate(){
            let output = {
                id: null,
                vanity: null,
                cid: null,
                client: null,
                notes: null,
                active: 'Y',
                launch: null,
                redirect: '/'
            }
            return output
        },
        formatDate(timestamp){
          let y = timestamp.getFullYear()
          let m = timestamp.getMonth()+1
          if(m<10){m = `0${m}`}
          let d = timestamp.getDate()
          if(d<10){d = `0${d}`}

          return `${y}-${m}-${d}`
        },
        async getCampaigns(){
            let self = this
            return new Promise(async (resolve, reject)=>{
                let path = self.$store.getters.UserController.authorized(['SuperAdmin','admin','staff','tester']) ? '/api/campaign/listAll' : '/api/campaign/list'
                try {
                    let response = await self.sendRequest('get',path)
                    let campaigns = response.data
                    for(let i=0; i<campaigns.length; i++){
                        let campaign = campaigns[i]
                        self.addRoute(campaign)
                    }
                    for(let i=0; i<response.data.length; i++){
                        let record = response.data[i]
                        let d = new Date(record.launch)
                        record.launch = self.formatDate(d)
                    }
                    self.getRoutes()
                    self.campaigns = campaigns
                    resolve(response)
                } catch (error) {
                    self.campaigns = []
                    reject(error)
                }

            })            
        },
        async getLogs(campaign){
            let self = this
            return new Promise(async(resolve, reject)=>{
                self.logs = null
                try {
                  let response = await self.sendRequest('post','/api/campaign/logs',campaign)
                  let logs = response.data
                  self.logs = logs
                  resolve(logs)
                } catch (error) {
                  console.error(error)
                  self.logs = []
                  reject(error)
                }

            })
        },
        async saveLog(data,event,params){
            let packet = JSON.stringify(data)
            packet = JSON.parse(packet)
            packet.event = event
            packet.params = params
            let response = await this.sendRequest('put','/api/campaign/logs',packet)
            return response
        },
        getRoutes(){
            this.routes = this.$router.getRoutes()
        },
        addRoute(campaign){
            let route =   {
                path: `/${campaign.vanity}`,
                name: campaign.vanity,
                component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
                meta: {
                    public: true,
                    allowed: '*',
                    campaign
                }
            }
            this.$router.addRoute(route)
        },
        async save(campaign){
            let self = this
            return new Promise(async (resolve, reject)=>{
                try {
                    let response = await self.sendRequest('post','/api/campaign',campaign)
                    resolve(response)
                } catch (error) {
                    reject(error)
                }
            })
        },
        storage(){
            let self = this
            let key = 'campaign'
            let expires = 2880//minutes
            return {
                async storeCampaign({vanity, cid}){
                    let d = new Date()
                    let timestamp = d.getTime()
                    let packet = {vanity, cid, timestamp}
                    self.storage().set(key,packet)
                },
                getCampaign(){
                    self.storage().expireOldCampaign()
                    let campaign = self.storage().get(key)
                    return campaign
                },
                expireOldCampaign(){
                    let campaign = self.storage().get(key)
                    if(campaign){
                        let d1 = new Date(campaign.timestamp)
                        let d2 = new Date()
                        let diff = (((d2-d1)/1000)/60)
                        if(diff>=expires){                                
                            self.storage().clear(key)
                        }
                    }
                },
                set: function(key, data){
                    let storage = window.localStorage;
    
                    if(storage){                    
                        try{
                            storage.setItem(key,JSON.stringify(data));
                            return true
                        }catch(error){
                            return false
                        }
                    }
    
                    return false
                },
                get: function(key){
                    let storage = window.localStorage;
                    let cache = storage.getItem(key);
                    if(cache){
                        cache = JSON.parse(cache);
                        return cache
                    }
                    return null
                },
                clear: function(key){
                    let storage = window.localStorage;
                    if(storage){
                        try{
                            storage.removeItem(key)
                            return true
                        }catch(error){
                            return false
                        }
                    }
                    return false
                }
            }
        }
    },
    computed:{
        campaign(){
            return this.$route.meta.campaign ? this.$route.meta.campaign : false
        }
    }
}
</script>

<style>

</style>
<template>
<div>    
    <div>
        <v-app-bar
            flat
            :app="false"
            :absolute="false"
            :fixed="false"
            :hide-on-scroll="false"
            height="90"      
            class="app-header"
            >

            <div style="position:relative; cursor:pointer; max-width:128px; min-width: 76px; display: flex; align-self: baseline; margin-top: -4px" @click="$router.push({name:'Home'})">
                <img alt="i-ACT Logo" :src="Logo" style="position:relative; width: 100%;"/>
            </div>

            <template v-if="$vuetify.breakpoint.width>1024">
            <div class="app-bar-item">
                <router-link nw-header="Home" :class="[{active:active('Home')}]" :to="{name:'Home'}"><Str index="header.home"/></router-link>
            </div>

            <div class="app-bar-item">
                <router-link nw-header="Dashboard" :class="[{active:active('Dashboard')}]" :to="{name:'Dashboard'}"><Str index="header.dashboard"/></router-link>
            </div>

            <div class="app-bar-item">
            <router-link nw-header="Educational Videos" :class="[{active:active('Educational Videos')}]" :to="{name:'Educational Videos'}">
                    <Str v-if="$store.getters.accreditation_expired!==true" :index="$store.getters.VideoController.watched.length>0 ? 'header.continue' : 'header.start'"/>
                    <Str v-else index="dashboard.user_progress.videos.title"/>
            </router-link>
            </div>

            <div class="app-bar-item" v-if="$store.getters.accreditation_expired!==true">
                <router-link nw-header="Invite" :class="[{active:active('Invite')}]" :to="{name:'Invite'}"><Str index="header.share"/></router-link>
            </div>

            <div class="app-bar-item" v-if="$store.getters.accreditation_expired!==true">
                <router-link nw-header="FAQ" :class="[{active:active('FAQ')}]" :to="{name:'FAQ'}"><Str index="header.faq"/></router-link>        
            </div>

            <div class="app-bar-item">
                <router-link v-if="!$store.getters.UserController.authenticated" nw-header="Sign In" :class="[{active:active('Sign In')}]" :to="{name:'Sign In'}"><Str index="header.sign_in"/></router-link>
                <a v-else nw-header="Profile" :class="[{active:active('Profile')}]" @click="$store.getters.UserController.profile.open()">
                    <Str index="header.my_account"/>
                </a>
            </div>

            <div id="language-container" :style="{'max-width': $vuetify.breakpoint.smAndDown ? '80px' : '96px'}">
                <Language/>
            </div>

            <div style="height:90px;">
            <v-btn class="custom-green" text :to="{name:'Contact Us'}" style="border-radius: 0px; padding: 20px; margin: 0px; height: 100%;" :class="{'px-2': $vuetify.breakpoint.smAndDown}">
                <Str :style="{'font-size': $vuetify.breakpoint.smAndDown ? '12px' : 'inherit'}" index="header.contact us"/>
            </v-btn>
            </div>
            </template>

            <v-app-bar-nav-icon dark class="ma-0" v-else id="nav-bar-icon" @click.stop="drawer=!drawer"></v-app-bar-nav-icon>          
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" temporary app dark overlay-opacity='0.7'>
            <v-list dense>
                <v-list-item link @click="drawer=false;">
                    <v-list-item-action class="mr-4">
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <router-link nw-header="Home" :class="[{active:active('Home')}]" :to="{name:'Home'}"><Str index="header.home"/></router-link>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="drawer=false;">
                    <v-list-item-action class="mr-4">
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <router-link nw-header="Dashboard" :class="[{active:active('Dashboard')}]" :to="{name:'Dashboard'}"><Str index="header.dashboard"/></router-link>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="drawer=false;">
                    <v-list-item-action class="mr-4">
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <router-link nw-header="Educational Videos" :class="[{active:active('Educational Videos')}]" :to="{name:'Educational Videos'}">
                                    <Str :index="$store.getters.VideoController.watched.length>0 ? 'header.continue' : 'header.start'"/>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="drawer=false;" v-if="$store.getters.accreditation_expired!==true">
                    <v-list-item-action class="mr-4">
                        <v-icon>mdi-share-variant</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <router-link nw-header="Invite" :class="[{active:active('Invite')}]" :to="{name:'Invite'}"><Str index="header.share"/></router-link>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="drawer=false;" v-if="$store.getters.accreditation_expired!==true">
                    <v-list-item-action class="mr-4">
                        <v-icon>mdi-frequently-asked-questions</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <router-link nw-header="FAQ" :class="[{active:active('FAQ')}]" :to="{name:'FAQ'}"><Str index="header.faq"/></router-link> 
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="drawer=false;">
                    <v-list-item-action class="mr-4">
                        <v-icon>mdi-account-cog</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <router-link v-if="!$store.getters.UserController.authenticated" nw-header="Sign In" :class="[{active:active('Sign In')}]" :to="{name:'Sign In'}"><Str index="header.sign_in"/></router-link>
                            <a v-else nw-header="Profile" :class="[{active:active('Profile')}]" @click="$store.getters.UserController.profile.open()">
                                <Str index="header.my_account"/>
                            </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-action class="mr-6">
                        <v-icon>mdi-translate</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <Language/>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item class="custom-green">
                    <v-list-item-action class="mx-9">
                        <router-link nw-header="Contact Us" :class="[{active:active('Contact Us')}]" :to="{name:'Contact Us'}"><Str style="font-size:14px !important;" index="header.contact us""/></router-link>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
    <v-row no-gutters class="text-center justify-center align-center bgcolor-brand-pink" v-if="$store.getters.accreditation_expired===true">
        <div id="closure-wrapper" style="max-width:900px" class="white--text pt-3 px-16">
            <h2 class="text-h5"><Str index="closure_note.title"/></h2>
            <p class="text-subtitle-1"><Str index="closure_note.notice"/></p>
        </div>
    </v-row>                
</div>    
</template>

<script>
import Logo from '@/assets/trap-logo.svg'
import FrLogo from '@/assets/trap-logo-fr.svg'
import Language from '@/components/Language'
export default {
    components: {
        Language
    },
    data(){
        return {
            drawer: false
        }
    },
    methods: {
        active(pathName){
            let matched = this.$route.matched
            let result = matched.filter((item)=>{return item.name==pathName})
            return result.length>0
        }
    },
    computed: {
        Logo(){
            return this.$store.getters.LanguageController.language==="fr-ca" ? FrLogo : Logo
        }
    }
}
</script>

<style lang="scss" scoped>
.app-header{
    background-color: $gray !important;
    z-index: 1; 
}
@media #{map-get($display-breakpoints, 'sm-and-down')}{
.app-header{
    //line-height:1;
    font-size:12px;
}
}
.header-col, .app-bar-item{
    align-self: center;
    text-align: center;
    display: contents;
}
a{
    text-decoration: none;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    color: white !important;
    font-weight: bold;
    font-size: 1em;
}
a:hover{
    color: $yellow !important;
}
a.active{
    color: $yellow !important;
}

.custom-green{
    background-color: $green;
}
.header-green{
    background-color: $green;
    color: white;
    font-weight: bold;
    padding: 25px;
}
::v-deep .v-toolbar__content {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
}

.desktop{
    display: inline-block;
}

.mobile{
    display: none;
}

@media only screen and (max-width: 1024px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inline-block;;
  }
  #closure-wrapper {
    padding-top: 40px !important;   
  }
}
</style>
<template>
<div :id="videoWrapper">
    <video :id="videoId" 
        class="video-player" 
        disablePictureInPicture 
        :autoplay="options && options.video ? options.video.autoplay===true : true" 
        :loop="options && options.video ? options.video.loop===true : false" 
        :muted="options && options.video ? options.video.muted===true : false"
        :controls="options && options.video ? options.video.controls===true : false"
        controlsList="nodownload"
        :playsinline="options && options.video ? options.video.playsinline===true : false"
        @play="playing" @resume="playing" @seeking="seeking" @pause="paused" @ended="ended">
        <source :src="source" type="video/mp4">
    Your browser does not support the video tag.
    </video>
</div>
</template>

<script>
export default {
// TODO: https://codepen.io/ykob/pen/jBjVEY        
    props: {
        videoId: {
            type: String,
            required: false,
            default: 'videoPlayer'
        },
        source: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            required: false
        }
    },
    mounted: function(){
        let videoPlayer = document.getElementById(this.videoId);
        videoPlayer.addEventListener('contextmenu', event => event.preventDefault());
        this.$emit('player',this.player);
    },
    beforeDestroy: function(){
        this.$store.getters.controllers.VideoController.player_idle()
        this.player.pause();
    },
    data: function(){
        return {
            timer: null,
            resumed: false
        }
    },
    methods: {
        playing: function(){
            let self = this;
            self.$store.getters.controllers.VideoController.player_play()

            let options = this.options;
            if(!self.resumed && options && options.resume){
                self.player.currentTime = (self.player.duration - options.resume) > 3 ? options.resume : 0;
                self.resumed = true;
            }
            clearInterval(self.timer)
            self.timer = setInterval(function(){
                self.$emit('playing',{currentTime:self.player.currentTime, duration: self.player.duration})
                if(self.player.paused){
                    clearTimeout(self.timer)
                }
            },5000)

        },
        ended: function(){
            let self = this;
            this.$store.getters.controllers.VideoController.player_end()
            // if(self.player.webkitExitFullscreen){self.player.webkitExitFullscreen()}
            // if(self.player.exitFullscreen){self.player.exitFullscreen()}
            self.$emit('ended')

            if(document.fullscreenElement){
                if(document.webkitExitFullscreen){
                    document.webkitExitFullscreen()
                }
                if(document.exitFullscreen){
                    document.exitFullscreen()
                }
            }
            // console.log('videoPlayer','ended')
        },
        paused: function(){
            this.$store.getters.controllers.VideoController.player_pause()
            clearInterval(this.timer)
            this.$emit('paused', {currentTime:this.player.currentTime, duration: this.player.duration})
        },
        seeking: function(){
            this.$store.getters.controllers.VideoController.player_seek()
            let self = this;

            self.$emit('seeking')
        }
    },
    computed: {
        $height: function(){
            return (window.innerHeight-25)+'px';
        },
        player: function(){
            return document.getElementById(this.videoId);
        },
        videoWrapper: function(){
            return `video-wrapper-${this.videoId}`
        }
    },
    watch: {
        // display: function(){
        //     let self = this;
        //     if(!self.display){
        //         self.player.pause();
        //         self.resumed = false;
        //         clearTimeout(self.timer);              
        //     }
            
        //     setTimeout(function(){
        //         self.player.load();
        //     },800)            
        // }
    }
}
</script>

<style css="scopped">  
#video-wrapper-bannerVideo:before {
/*    
  content:"";
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  z-index:0;
  background: linear-gradient(to right,rgba(0, 0, 0, 0.34),rgba(0, 0, 0, 0.76));
*/
}

#video-wrapper-bannerVideo {
    max-width: 659px;
    /*
    left: 0%;
    top: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 0;
    max-height: 720px;
    */
}

.video-player {
    object-fit: contain;
    max-width:100%;
    width: 100%;
    height: 100%;
    position: inherit;
}
</style>
<style>
.vjs-slider{ pointer-events: none }
</style>
<template>
<v-container fluid class="app-footer">
    <v-row justify="space-around" align="center" :class="['py-3', {'flex-column':$vuetify.breakpoint.xsOnly}]">
        <v-col :cols="12" :md="4"/>
        <v-col :cols="12" :md="4" class="d-flex justify-center px-1 text-center">
            <a href="http://ctccomm.com" target="_blank" v-html="ctc_copyrights_label"/>            
        </v-col>
        <v-col :cols="12" :md="4" :class="['d-flex', 'justify-space-evenly', 'align-center', 'text-center', 'px-1', {'flex-column':$vuetify.breakpoint.xsOnly}]">
            <div v-for="(link, index) in links" :key="`link-${index}`">               
                <router-link :class="[{active:isActive(link.pathName)}]" :to="{name:link.pathName}"  v-html="link.label"></router-link>
            </div>
            
            <!--
            <v-btn v-if="$store.getters.UserController.authenticated" x-large color="green" tile dark @click="$store.getters.UserController.signOut()">
                <Str index="site.common.sign_out"/>
            </v-btn>
            -->
        </v-col>            
    </v-row>            
</v-container>
</template>
  
<script>
export default {
  props: {
    language: {
      type: String,
      required: false,
      default: 'en-ca'
    }
  },
  data(){
    return {
        ctc_copyrights_label: "<u>CTC Communications</u>&nbsp;&copy;&nbsp;" + new Date().getFullYear()+". All Rights Reserved"
    }
  },
  methods: {
    isActive(pathName){
          let matched = this.$route.matched
          let result = matched.filter((item)=>{return item.name==pathName})
          return result.length>0
    }
  },
  computed: {
    controller(){
      return this.$store.getters.LabelController ? this.$store.getters.LabelController : undefined
    },
    links(){
      let output = []
      let controller = this.controller
      if(controller){
        output = [       
          {
            label: this.controller.getLabel('footer.terms of use'),
            pathName: 'Terms of Use'
          },
          {
            label: '<span class="color-brand-yellow">|</span>',
            pathName: null
          },
          {
            label: this.controller.getLabel('footer.privacy policy'),
            pathName: 'Privacy Policy'
          },
          {
           label: '<span class="color-brand-yellow">|</span>',
            pathName: null
          },          
          {
            label: this.controller.getLabel('footer.disclosures'),
            pathName: 'Disclosures'
          }
        ]
      }

      return output
    }
  }
}
</script>
  
<style lang="scss" scoped>
.app-footer{
    background-color: $gray;
    color: white;
    font-size: 16px;
}
a{
    text-decoration: none;
    margin: 5px;
    color: white !important;
}

.link:hover,
a:hover{
    color: $yellow !important;
}

.link:hover,
a.active{
    color: $yellow !important;
}

@media only screen and (max-width: 374px) {
.app-footer{
    font-size: 13px;
}
}
</style>
  
  
  
  